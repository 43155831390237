(function () {
	'use strict';
	angular.module('app').factory('manageFetch', [manageFetch]);

	var fetchCallbacks = {};
	var unscheduledFetchCallbacks = {};
	var fetchID = 0;

	class EventsCallback {
		/** @type {(scheduleID: string, fetchID: number, type: 'event' | 'unscheduled', callback: function) => void} */
		constructor(scheduleID, fetchID, type, callback) {
			this.scheduleID = scheduleID;
			this.fetchID = fetchID;
			this.type = type;
			this.callback = callback;
			this.enable = true;

			if (type === 'event' && !fetchCallbacks[scheduleID]) {
				fetchCallbacks[scheduleID] = {};
			}

			if (
				type === 'unscheduled' &&
				!unscheduledFetchCallbacks[scheduleID]
			) {
				unscheduledFetchCallbacks[scheduleID] = {};
			}
		}

		/** @type {(result: any) => number} */
		confirm = (result) => {
			if (this.enable) {
				this.callback(result);
			}
			if (this.type === 'unscheduled') {
				delete unscheduledFetchCallbacks[this.scheduleID][this.fetchID];
			} else {
				delete fetchCallbacks[this.scheduleID][this.fetchID];
			}
			return fetchID;
		};

		/** @type {() => number} */
		cancel = () => {
			this.enable = false;
			this.callback([]);
			if (this.type === 'unscheduled') {
				delete unscheduledFetchCallbacks[this.scheduleID][this.fetchID];
			} else {
				delete fetchCallbacks[this.scheduleID][this.fetchID];
			}
			return fetchID;
		};
	}

	function manageFetch() {
		return {
			create: create,
			clear: clear,
		};

		/** @type {(scheduleID: string, type: 'event' | 'unscheduled', callback: Function) => EventsCallback} */
		function create(scheduleID, type, callback) {
			clear(scheduleID, type);

			fetchID++;
			const fetchInstance = new EventsCallback(
				scheduleID,
				fetchID,
				type,
				callback
			);
			if (type === 'unscheduled') {
				unscheduledFetchCallbacks[scheduleID][fetchID] = fetchInstance;
			} else {
				fetchCallbacks[scheduleID][fetchID] = fetchInstance;
			}
			return fetchInstance;
		}

		/** @type {(scheduleID: string, type?: 'event' | 'unscheduled') => void} */
		function clear(scheduleID, type) {
			if (!type || type === 'event') {
				// Events
				for (const property in fetchCallbacks[scheduleID]) {
					fetchCallbacks[scheduleID][property].cancel();
				}
			}

			if (!type || type === 'unscheduled') {
				// Unscheduled
				for (const property in unscheduledFetchCallbacks[scheduleID]) {
					unscheduledFetchCallbacks[scheduleID][property].cancel();
				}
			}
		}
	}
})();
